<template>
    <div>
        <b-row class="mb-3 position-sticky z-2" style="top: 4rem; z-index: 10">
            <b-col>
                <b-button
                    @click="$router.back()"
                    class="ml-1 p-2 rounded-circle shadow"
                    variant="primary"
                >
                    <b-icon-arrow-left font-scale="2" />
                </b-button>
            </b-col>
        </b-row>
        <b-card class="px-1 mb-4" ref="firstBlock">
            <b-card-body>
                <h4 class="font-weight-bold text-truncate text-capitalize" style="max-width: 100%">
                    <span class="text-muted"> {{ $t('editUserTitle') }}: </span>
                    {{ userName }}
                </h4>

                <UserForm
                    v-if="activeUser"
                    :key="`${activeUser.userId} + ${loading}`"
                    :value="activeUser"
                    edit-mode
                    @submit="updateUser"
                />
            </b-card-body>
        </b-card>

        <b-card class="px-1">
            <b-card-body>
                <h4 class="font-weight-bold text-muted text-capitalize">
                    {{ $t('editAssociationsTitle') }}
                </h4>

                <EditAssociatedAccounts />
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import UserForm from '@/views/admin/components/usermanagement/UserForm.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import EditAssociatedAccounts from '@/views/admin/components/usermanagement/EditAssociatedAccounts.vue';

export default {
    components: {EditAssociatedAccounts, UserForm},
    props: {
        userId: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        form: {},
        loading: false,
    }),
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
        ...mapState({
            roles: (state) => state.roles.list,
            activeUser: (state) => state.userList.activeUser,
            businessUnits: (state) => state.user.businessUnits,
        }),
        userName() {
            return `${this.activeUser?.firstName || this.$t('unknownName')} ${
                this.activeUser?.secondName || this.activeUser?.lastName || ''
            }`;
        },
    },
    methods: {
        ...mapActions('user', ['fetchMyForcedLogoutStatus']),
        isCurrentUser({userId}) {
            return this.currentUser.userId === userId;
        },
        async updateUser(data) {
            try {
                await this.blockingRequest('userList/updateUser', {
                    userId: data.userId,
                    email: data.email,
                    language: data.language,
                    cultureCode: data.language,
                    bulkOrdering: data.bulkOrdering,
                    consignmentRights: data.consignmentRights,
                    decimalSeparator: data.decimalSeparator,
                    role: {roleId: data.role},
                    businessUnits: data.businessUnits.map((value) => ({businessUnitId: value})),
                    isEnabled: data.isEnabled,
                    firstName: data.firstName,
                    lastName: data.lastName,
                });
                if (this.isCurrentUser(data)) {
                    await this.fetchMyForcedLogoutStatus();
                    if (this.currentUser.forcedLogoutStatus) {
                        this.$router.push({name: 'LoggingOut'});
                    }
                }
                this.$bvToast.toast(this.$t('userUpdatedText'), {
                    title: this.$t('userUpdatedTitle'),
                    variant: 'success',
                    solid: true,
                });
                if (this.currentUser.userId === data.userId) {
                    this.$store.dispatch('user/setBulkOrdering', data.bulkOrdering);
                }
            } catch (err) {
                this.$bvToast.toast(this.$t('unableToUpdateUser'), {
                    title: this.$t('error'),
                    variant: 'danger',
                });
            }
        },
    },
    async beforeMount() {
        this.loading = true;
        await this.blockingRequest('userList/getUser', this.userId);
        if (!this.roles.length) {
            await this.blockingRequest('roles/fetchList');
        }
        this.loading = false;
    },
};
</script>
